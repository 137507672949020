import React from 'react'
import './styles.sass'
import Logo from '../../Logo'
import UserAvatar from '../../../assets/temporary/user-avatar.png'

const Sidebar = () => {
	return (
		<div className='sidebar'>
			<div className='sidebar-header'>
				<Logo type='full' viewStyle='white' />
			</div>
			<div className='sidebar-navigation'>
				<div className='sidebar-navigation-item'></div>
			</div>
			<div className='sidebar-bottom'>
				<div className='user-information'>
					<div className='user-avatar'>
						<img src={UserAvatar} alt='User avatar' />
					</div>
					<div className='user-contact'>
						<div className='user-contact-fullname'>
							<span>Ahrorbek Mirzaev</span>
						</div>
						<div className='user-contact-phone'>
							<span>+998 (77) 353-88-88</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Sidebar
