// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 248px;
  min-height: 100%;
  background: #001E43;
}
.sidebar .sidebar-header {
  margin-top: 20px;
  margin-bottom: 20px;
}
.sidebar .sidebar-navigation {
  flex-grow: 1;
  padding-top: 100px;
}
.sidebar .sidebar-bottom {
  margin-top: 20px;
  margin-bottom: 20px;
}
.sidebar .sidebar-bottom .user-information {
  display: flex;
  position: relative;
  gap: 10px;
  padding: 0 36px;
  cursor: pointer;
}
.sidebar .sidebar-bottom .user-information .user-avatar {
  display: flex;
  position: relative;
  width: 32px;
  margin: auto;
}
.sidebar .sidebar-bottom .user-information .user-avatar img {
  margin: auto;
  border-radius: 100%;
  width: 100%;
}
.sidebar .sidebar-bottom .user-information .user-contact {
  margin: auto 0;
}
.sidebar .sidebar-bottom .user-information .user-contact-fullname span, .sidebar .sidebar-bottom .user-information .user-contact-phone span {
  color: white;
  font-size: 12px;
  font-weight: 400;
}
.sidebar .logo {
  padding: 0 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/Sidebar/styles.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAAE;EACE,gBAAA;EACA,mBAAA;AAEJ;AADE;EACE,YAAA;EACA,kBAAA;AAGJ;AAFE;EACE,gBAAA;EACA,mBAAA;AAIJ;AAHI;EACE,aAAA;EACA,kBAAA;EACA,SAAA;EACA,eAAA;EACA,eAAA;AAKN;AAHQ;EACE,aAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAKV;AAJU;EACE,YAAA;EACA,mBAAA;EACA,WAAA;AAMZ;AALQ;EACE,cAAA;AAOV;AALY;EACE,YAAA;EACA,eAAA;EACA,gBAAA;AAOd;AANE;EACE,eAAA;AAQJ","sourcesContent":[".sidebar\n  position: relative\n  display: flex\n  flex-direction: column\n  justify-content: space-between\n  width: 248px\n  min-height: 100%\n  background: #001E43\n  .sidebar-header\n    margin-top: 20px\n    margin-bottom: 20px\n  .sidebar-navigation\n    flex-grow: 1\n    padding-top: 100px\n  .sidebar-bottom\n    margin-top: 20px\n    margin-bottom: 20px\n    .user-information\n      display: flex\n      position: relative\n      gap: 10px\n      padding: 0 36px\n      cursor: pointer\n      .user\n        &-avatar\n          display: flex\n          position: relative\n          width: 32px\n          margin: auto\n          img\n            margin: auto\n            border-radius: 100%\n            width: 100%\n        &-contact\n          margin: auto 0\n          &-fullname, &-phone\n            span\n              color: white\n              font-size: 12px\n              font-weight: 400\n  .logo\n    padding: 0 24px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
