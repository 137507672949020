const dashboardRoutes = [
	{
		index: true,
		path: '',
		element: <div>Dashboard page</div>,
	},
	{
		path: 'companies',
		element: <div>Companies page</div>,
	},
	{
		path: 'trucks',
		element: <div>Trucks page</div>,
	},
	{
		path: 'trailers',
		element: <div>Trailers page</div>,
	},
]

export default dashboardRoutes
