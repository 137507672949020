// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-dashboard {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.layout-dashboard .container {
  display: flex;
  position: relative;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layouts/DashboardLayout/styles.sass"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,YAAA;AAAJ;AACI;EACE,aAAA;EACA,kBAAA;EACA,YAAA;AACN","sourcesContent":[".layout\n  &-dashboard\n    position: absolute\n    top: 0\n    right: 0\n    bottom: 0\n    left: 0\n    margin: auto\n    .container\n      display: flex\n      position: relative\n      height: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
