const authorizationRoutes = [
	{
		index: true,
		path: 'sign-in',
		element: <div>Sign In page</div>,
	},
	{
		path: 'sign-up',
		element: <div>Sign Up page</div>,
	},
	{
		path: 'restore',
		element: <div>Restore page</div>,
	},
]

export default authorizationRoutes
