// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.sass"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;EACA,gCAAA;EACA,yBAAA;EACA,kBAAA;AAAF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap')\n  \nhtml, body\n  margin: 0\n  padding: 0\n  font-family: \"Inter\", sans-serif\n  font-optical-sizing: auto\n  font-style: normal"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
