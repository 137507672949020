import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/dashboard/Sidebar'
import './styles.sass'

const DashboardLayout = () => {
	return (
		<div className='layout layout-dashboard'>
			<div className='container'>
				<Sidebar />
				<Outlet />
			</div>
		</div>
	)
}

export default DashboardLayout
