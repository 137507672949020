import { createBrowserRouter } from 'react-router-dom'
import authorizationRoutes from './auth'
import AuthorizationLayout from '../layouts/AuthorizationLayout'
import DashboardLayout from '../layouts/DashboardLayout'
import dashboardRoutes from './dashboard'

const AppRoutes = createBrowserRouter([
	// Authorization Layout and Routes
	{
		path: '/auth',
		element: <AuthorizationLayout />,
		children: authorizationRoutes,
	},
	// Dashboard Layout and Routes
	{
		path: '/dashboard',
		element: <DashboardLayout />,
		children: dashboardRoutes,
	},
])

export default AppRoutes
